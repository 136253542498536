.header {
  padding: 2rem 0;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  position: sticky;
  top: 0;
  background-color: #800040;
  z-index: 999;
}

.header__wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 1rem;
}

.header__logo {
  color: white;
  filter: drop-shadow(2px 4px 6px black);
}

.header__logo img {
  height: 50px;
  width: 100px;
}

.header__menu {
  font-size: 2.5rem;
  color: #ffffff !important;
  display: flex;
  justify-content: flex-end;
  padding: 0;
}

.header__close {
  font-size: 2.5rem;
  color: #ffffff;
  margin: 1rem;
  display: flex;
  justify-content: flex-end;
  padding: 0;
}

.header__close svg {
  border: 1px solid white;
}

.nav {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  background-color: #800040;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.nav > li {
  list-style-type: none;
  margin: 1rem 0.5rem;
}

.header__nav__link {
  color: white;
  text-decoration: none;
}

.header__nav__link:hover {
  text-decoration: underline;
}

.badge {
  background-color: white;
  color: #800040;
  border-radius: 12px;
  padding: 0 1rem;
  margin-left: 0.5rem;
}
