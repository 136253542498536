.nav_div {
  display: flex;
  flex-direction: column;
  max-width: 30rem;
  width: 100%;
  margin: 0 auto;
}

.nav_div > li {
  list-style-type: none;
  margin: 1rem 0.5rem;
  border-bottom: 1px solid white;
  padding-bottom: 0.25rem;
}

.header__nav__link {
  color: white;
  text-decoration: none;
}

.header__nav__link:hover {
  text-decoration: underline;
}

.badge {
  background-color: white;
  color: #800040;
  border-radius: 12px;
  padding: 0 1rem;
  margin-left: 0.5rem;
}
