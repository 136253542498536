.icon_sizes {
  height: 2rem;
  width: 2rem;
  color: #800040;
}

.icon_liststyle {
  display: flex;
  justify-content: flex-end;
  list-style-type: none;
}

.icon_listlist {
  margin: 0.5rem;
}

.widget_align {
  text-align: right;
}
