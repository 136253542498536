.item {
  margin: 1rem 1rem;
  list-style: none;
}

.item:hover {
  .content {
    opacity: 1;
    transform: translateY(0);
    transition-delay: 0.1s;
    transition-duration: 0.5s;
  }
  .actions {
    opacity: 1;
    transform: translateY(0);
    transition-delay: 0.1s;
    transition-duration: 0.5s;
  }

  /* Keyframe not working. Fix it */

  .image img {
    height: 300px !important; 
    transition: height 0.2s;
  }
}

.image {
  width: 100%;
  height: 20rem;
  padding: 1rem;
  /* overflow: hidden; */
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}

.image img {
  width: 100%;
  height: 32rem !important;
  transition: height 0.2s;
  object-fit: cover;
}

.content {
  text-align: center;
  padding: 1rem;
  opacity: 0;
  transform: translateY(5%);
}

.content h3 {
  font-size: 1.25rem;
  color: #2c292b;
}

.actions {
  padding: 1rem;
  text-align: center;
  opacity: 0;
  transform: translateY(5%);
}

.actions button {
  font: inherit;
  cursor: pointer;
  color: #77002e;
  border: 1px solid #77002e;
  background-color: transparent;
  padding: 0.5rem 1.5rem;
  border-radius: 4px;
}

.actions button:hover,
.actions button:active {
  background-color: #ffe2ed;
}