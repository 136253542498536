.list {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  animation: primary 35s linear infinite;
}

.list:hover {
  animation-play-state: paused;
}

.content_topbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.content_topbar_links {
  margin: 0 0.5rem;
  text-decoration: none;
}

.content_topbar_links:hover {
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.75);
}

@keyframes primary {
  0% {
    transform: translateX(-15%);
  }
  100% {
    transform: translateX(calc(-180px * 12));
  }
  /* 0% {
    transform: translateX(0);
  } */
}

/* @keyframes primary {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
} */

/* ============================== */
/* @keyframes scroll-left-to-right {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

@keyframes scroll-right-to-left {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.scroll-left-to-right {
  animation: scroll-left-to-right 10s linear infinite;
}

.scroll-right-to-left {
  animation: scroll-right-to-left 10s linear infinite;
} */
