.main {
  margin: 3rem auto;
  padding: 0rem 10rem;
  width: 90%;
}

.footer {
  height: 100px;
  width: 100%;
  background-color: #800040;
  color: white;
  text-align: center;
}

.footer_tag {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem 0;
}

.footer_icon {
  color: white;
  font-size: 2rem;
  margin-left: 0.5rem;
}

@media (max-width: 1500px) {
  .main {
    padding: 0rem 2rem;
  }
}

@media (max-width: 875px) {
  .main {
    padding: 0rem 0.5rem;
  }
}

@media (max-width: 545px) {
  .main {
    margin: 3rem 0.5rem;
  }
}

@media (max-width: 487px) {
  .main {
    margin: 3rem 0.5rem;
  }
}
