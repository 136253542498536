.wrapper {
  margin: 1rem 1rem;
  min-height: 10rem;
  border-radius: 50px 10px;
  padding: 0.5rem 3.2rem;
  background-color: aliceblue;
}

.wrapper:hover {
  box-shadow: 0px 0px 10px 0px rgba(119, 0, 46, 0.75);
  -webkit-box-shadow: 0px 0px 10px 0px rgba(119, 0, 46, 0.75);
  -moz-box-shadow: 0px 0px 10px 0px rgba(119, 0, 46, 0.75);
}

.component_button_wrap {
  display: flex;
  flex-direction: row-reverse;
  margin: 1rem 0;
}

.component_button {
  text-decoration: none;
}

@media (max-width: 545px) {
  .wrapper {
    padding: 0.5rem 1rem;
  }
}
