.post_wrapper {
  display: flex;
  margin: 2rem 0rem;
  padding: 1rem 0rem;
  text-decoration: none;
}

.post_wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 2px solid gray;
  border-radius: 5px;
  padding: 1rem 1rem;
  width: 100%;
}

.read_more {
  text-decoration: none;
  border: 2px solid #800040;
  border-radius: 3px;
  color: #800040;
  background-color: white;
  padding: 0.5rem;
  margin-left: auto;
  max-width: fit-content;
}

.read_more:hover {
  color: white;
  background-color: #800040;
}

.image_wrapp {
  width: 100%;
  height: 250px;
  object-fit: cover;
}

.heading {
  /* padding: 2rem 1rem 2rem 0rem;
  max-width: 10rem; */
}

.loading {
  margin: auto;
}

/* Page Loader */
.loader {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loadercircle {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #ffffff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  animation: pulse 1.5s ease-in-out infinite;
}

.loadercircle:before {
  content: "";
  display: block;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border: 8px solid #800040;
  border-color: #800040 transparent #800040 transparent;
  animation: loader 1.2s linear infinite;
}

.loadertext {
  color: #800040;
  font-size: 24px;
  font-weight: bold;
  margin-top: 16px;
}

@keyframes loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes pulse {
  0% {
    transform: scale(0.8);
    opacity: 0.5;
  }
  50% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0.8);
    opacity: 0.5;
  }
}

/* Filter */
.wp_filter_options {
  display: flex;
  justify-content: space-between;
}

/* Wp post view */
.wp_blockk {
  display: block;
}

.wp_gridd {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 1rem;
}

/* Single post page */
.single_post_featured_image {
  width: 100%;
  height: 250px;
  object-fit: cover;
}

/* Responsive CSS */
@media screen and (max-width: 1024px) {
  .wp_gridd {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 767px) {
  .wp_gridd {
    grid-template-columns: repeat(1, 1fr);
  }
}
