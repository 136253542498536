.toggle_container {
  width: 70px;
  background-color: #c4c4c4;
  cursor: pointer;
  user-select: none;
  border-radius: 3px;
  padding: 2px;
  margin-bottom: 2rem;
  height: 32px;
  position: relative;
}

.dialog_button {
  font-size: 14px;
  line-height: 12px;
  font-weight: bold;
  cursor: pointer;
  background-color: #800040;
  color: white;
  padding: 8px 12px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 38px;
  min-width: unset;
  border-radius: 3px;
  box-sizing: border-box;
  position: absolute;
  left: 34px;
  transition: all 0.3s ease;
}

.disabled {
  background-color: #707070;
  left: 2px;
}

/* Filter Options */
.wp_filter_options {
  /* height: 100px; */
  width: 100%;
  background-color: rgba(255, 255, 255, 0.507);
}

.wp_filter_show {
  /* display: block; */
  height: 200px;
  opacity: 1;
  animation: toggleopen 0.4s ease-in;
}

.wp_filter_hide {
  /* height: 0px; */
  animation: toggleclose 0.1s ease-out;
  /* display: none; */
  opacity: 0;
}

@keyframes toggleopen {
  from {
    height: 0px;
    opacity: 0;
  }
  to {
    height: 200px;
    opacity: 1;
  }
}

@keyframes toggleclose {
  from {
    height: 200px;
    opacity: 1;
  }
  to {
    height: 0px;
    opacity: 0;
  }
}
